import React from "react";
import homebg from "../img/hero/hero-bg-4.png";
import appleStoreBadge from "../img/hero/apple_store_badge.svg";
import shape2 from "../img/hero/shape-2.png";
import shape3 from "../img/hero/shape-3.png";
import mobile1 from "../img/screen/trusted_bakers_main_screen.png";

const Hero = () => {
    return (
        <>
            <div
                id="apork-hero"
                className="apork-hero-area hero-bg position-relative"
                style={{background: `url('${homebg}')`}}
            >
                <div className="hero-style-shape">
                    <img className="style-shape shape-2" src={shape2} alt="imgshape"/>
                    <img
                        className="style-shape aporkrot shape-3"
                        src={shape3}
                        alt="imgshape"
                    />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8 col-md-12">
                            <div className="hero-content">
                                <h2>
                                    Find Local Trusted Bakers
                                </h2>
                                <p>
                                </p>
                                <p>
                                    Trusted Bakers is your one-stop-shop for finding the perfect cake for any occasion.
                                    We connect you with local independent bakers who specialize in creating custom cakes
                                    that are not only delicious, but also visually stunning.
                                </p>
                                <p>
                                    From weddings to birthdays and everything in between, our local bakers have a wide
                                    range of themes and designs to choose from. You'll get the freshest ingredients and
                                    personalized service since all our bakes are made locally near you!
                                </p>
                                <p>
                                    Connect with our Trusted Bakers for quality cakes at competitive prices.
                                </p>
                                <div className="hero-btn">
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-4">
                                            <a href='https://play.google.com/store/apps/details?id=com.innovotech.trustedbakers'>
                                                <div className="play-store-badge">
                                                    <img alt='Get it on Google Play'
                                                         src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-xs-6 col-sm-4">
                                            <a href='https://apps.apple.com/us/app/trusted-bakers/id1659158536'>
                                                <div className="app-store-badge">
                                                    <img alt='Get it on Apps Store'
                                                         src={appleStoreBadge}/>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 offset-xl-1 col-lg-4 wow fadeInRight">
                            <div className="hero-mobile-img">
                                <img src={mobile1} alt="imgmobile"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
