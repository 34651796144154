import React from "react";
import shape1 from "../img/features/bg-shape-1.png";
import shape2 from "../img/features/bg-shape-2.png";
import chromeExtIcon from "../img/chrome-webstore-icon.png";
import icon3 from "../img/screen/websafe365-scan-qr.png";
import fireFoxIcon from "../img/firefox-add-on.png";
import microsoftLogo from "../img/hero/microsoft-logo.jpg";

import ishape1 from "../img/features/shape-1.png";
import ishape2 from "../img/features/shape-2.png";
import ishape3 from "../img/features/shape-3.png";
import appleStoreBadge from "../img/hero/apple_store_badge.svg";

const Features = () => {
  return (
      <>
        <div
            id="apork-features-area"
            className="apork-features-area position-relative"
        >
          <div className="features-bg-shape">
            <img src={shape1} alt="featuresimg" className="style-shape fbs-1" />
            <img src={shape2} alt="featuresimg" className="style-shape fbs-2" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 mx-auto">
                <div className="section-title text-center mt-80 mb-20">
                  <h2>How to Setup</h2>
                  <p>3 easy steps. Setup within minutes.</p>
                </div>
              </div>
            </div>

            <div className="row text-center">
              <div className="col-xl-4 col-lg-4 col-md-4 wow fadeInLeft">
                <div className="single-features">
                  <a href='https://play.google.com/store/apps/details?id=com.innovotech.trustedbakers'>
                    <div className="play-store-badge">
                      <img alt='Get it on Google Play'
                           src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                    </div>
                  </a>
                  <a href='https://apps.apple.com/us/app/trusted-bakers/id1659158536'>
                    <div className="app-store-badge">
                      <img alt='Get it on Apps Store'
                           src={appleStoreBadge}/>
                    </div>
                  </a>
                  <h2>Step 1</h2>
                  <h3>Install Trusted Bakers mobile App</h3>
                  <p>
                    Download Trusted Bakers parent mobile App for Android or Apple iOS device
                  </p>
                  <div className="features-shape">
                    <img src={ishape1} alt="featuresimg" />
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 wow fadeInUp">
                <div className="single-features">
                  <a href="https://chrome.google.com/webstore/detail/websafe365/caalgailaopfobcgmhcglflekfefpcdn">
                    <img src={chromeExtIcon} alt="featuresimg" />
                  </a>
                  <a href="https://microsoftedge.microsoft.com/addons/detail/websafe365/fkpmcfdhmoecfjdmmoddmclhdjacojmc">
                    <img src={microsoftLogo} alt="featuresimg" />
                  </a>
                  <a href="https://addons.mozilla.org/en-GB/firefox/addon/websafe365/">
                    <img src={fireFoxIcon} alt="featuresimg" />
                  </a>
                  <h2>Step 2</h2>
                  <h3>Install WebSafe365 web browser extension</h3>
                  <p>
                    click compatible browser icon to download the Trusted Bakers extension on kids Computer. You can install Trusted Bakers on all 3 browsers (Chrome/Firefox/EDGE).
                  </p>
                  <div className="features-shape">

                    <img src={ishape2} alt="featuresimg" />


                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 wow fadeInRight">
                <div className="single-features">
                  <img src={icon3} alt="featuresimg" />
                  <h2>Step 3</h2>
                  <h3>Scan QR Code</h3>
                  <p>
                    Open WebSafe365 mobile app > Settings > Pair Chrome Extension
                  </p>
                  <div className="features-shape">
                    <img src={ishape3} alt="featuresimg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default Features;
