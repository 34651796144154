import React from "react";
import shape1 from "../img/features/bg-shape-1.png";
import shape2 from "../img/features/bg-shape-2.png";
import icon1 from "../img/features/icon-1.png";
import icon2 from "../img/features/icon-2.png";
import icon3 from "../img/features/icon-3.png";
import ishape1 from "../img/features/shape-1.png";
import ishape2 from "../img/features/shape-2.png";
import ishape3 from "../img/features/shape-3.png";

const Features = () => {
  return (
      <>
        <div
            id="apork-features-area"
            className="apork-features-area position-relative mt-80 mb-100"
        >
          <div className="features-bg-shape">
            <img src={shape1} alt="featuresimg" className="style-shape fbs-1" />
            <img src={shape2} alt="featuresimg" className="style-shape fbs-2" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 mx-auto">
                <div className="section-title text-center mb-80">
                  <h2>Features</h2>
                  <p>
                    Browse stunning cakes, designs & bakers near you. Bakers can start listing just from our mobile app.
                  </p>
                </div>
              </div>
            </div>

            <div className="row text-center">
              <div className="col-xl-4 col-lg-4 col-md-4 wow fadeInLeft">
                <div className="single-features">
                  <img src={icon1} alt="featuresimg" />
                  <h3>Discover Cakes & Bakers</h3>
                  <ul>
                    <li>Search for bakers and cake designs.</li>
                    <li>Use cake themes, keywords and postcodes for precise results.</li>
                    <li>Shortlist favourites with bookmark feature.</li>
                  </ul>
                  <div className="features-shape">
                    <img src={ishape1} alt="featuresimg" />
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 wow fadeInUp">
                <div className="single-features">
                  <img src={icon2} alt="featuresimg" />
                  <h3>Engage & Stay Updated</h3>
                  <ul>
                    <li>Pick your collection/delivery date for designs.</li>
                    <li>Get quick responses from our bakers.</li>
                    <li>Chat about allergies or customizations directly.</li>
                  </ul>
                  <div className="features-shape">
                    <img src={ishape2} alt="featuresimg" />
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 wow fadeInRight">
                <div className="single-features">
                  <img src={icon3} alt="featuresimg" />
                  <h3>Easy Product Listing</h3>
                  <ul>
                    <li>if you are a Baker, use our app to list bakery products.</li>
                    <li>Effortless product uploads with just a few taps.</li>
                  </ul>
                  <div className="features-shape">
                    <img src={ishape3} alt="featuresimg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default Features;
