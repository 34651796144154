import React from "react";
import shape1 from "../img/features/bg-shape-1.png";
import shape2 from "../img/features/bg-shape-2.png";
import ishape1 from "../img/features/shape-1.png";
import ishape3 from "../img/features/shape-3.png";
import mobile1 from "../img/screen/trusted_bakers_main_screen.png";
import appleStoreBadge from "../img/hero/apple_store_badge.svg";

const Features = () => {
  return (
    <>
      <div
        id="apork-features-area"
        className="apork-features-area position-relative"
      >
        <div className="features-bg-shape">
          <img src={shape1} alt="featuresimg" className="style-shape fbs-1" />
          <img src={shape2} alt="featuresimg" className="style-shape fbs-2" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 mx-auto">
              <div className="section-title text-center mb-40">
                <h2>How It works</h2>
                <h5>
                  Download our app and you are good to go.
                </h5>
              </div>
            </div>
          </div>

          <div className="row text-center d-flex align-items-stretch">
            <div className="col-xl-6 col-lg-6 col-md-6 wow fadeInLeft">
              <div className="single-features h-100">
                <a href='https://play.google.com/store/apps/details?id=com.innovotech.trustedbakers'>
                  <div className="play-store-badge">
                    <img alt='Get it on Google Play'
                         src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                         style={{ maxHeight: '150px' }}/>
                  </div>
                </a>
                <a href='https://apps.apple.com/us/app/trusted-bakers/id1659158536'>
                  <div className="app-store-badge">
                    <img alt='Get it on Apps Store'
                         src={appleStoreBadge}
                         style={{ maxHeight: '150px' }}/>
                  </div>
                </a>
                <h3>Install Trusted Bakers mobile App</h3>
                <p>
                  Download Trusted Bakers mobile App for Android or Apple iOS device
                </p>
                <div className="features-shape">
                  <img src={ishape1} alt="featuresimg" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 wow fadeInRight">
              <div className="single-features h-100">
                <h2 className="text-highlighter">Our App</h2>
                <img src={mobile1} alt="featuresimg" className="image-size"/>
                <div className="features-shape">
                  <img src={ishape3} alt="featuresimg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
