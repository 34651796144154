import React from "react";

import aiImage1 from "../img/screen/trusted_bakers_choices1.png";

const About = () => {
  return (
    <>
      <div id="apork-about-area" className="apork-about-area mt-230">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 mx-auto">
              <div className="section-title mb-80">
                <h4 className="text-highlighter text-center">Discover Cakes, Designs, and More with the TrustedBakers!</h4>
                <p className="mt-3 text-center">TrustedBakers brings together exquisite designs and delicious bakes from Home Bakers and Bakeries.</p>
                <p className="mt-3 text-center">Whether you're searching for the ideal cake or showcasing your baking skills, our platform connects bakers and those eager to find unique baked creations for their special occasion.</p>

                <div className="mt-50 mb-50 center">
                  <img src={aiImage1} alt="bakers offering various cake designs"  />
                </div>
                <h4 className="text-highlighter mt-3 text-center">For Customers:</h4>
                <ul className="mt-3">
                  <li><strong>Diverse Selection:</strong> From birthday blowouts to elegant weddings, our local bakers craft cakes tailored to your occasion, offering a multitude of themes and designs.</li>
                  <li><strong>Direct Engagement:</strong> Enquire and chat directly with bakers, discuss customisations, and get all your allergy information clarified.</li>
                  <li><strong>Guaranteed Freshness:</strong> With cakes sourced from your neighbourhood, rest assured in the knowledge that each bite offers the freshest ingredients.</li>
                  <li><strong>Quality Assurance:</strong> Beyond taste, we prioritise safety. Each of our listed bakers holds a Level 2 or higher Food Hygiene certificate and undergoes regular inspections.</li>
                  <li><strong>Hassle-Free Experience:</strong> Skip the tedious search from multiple platforms. With our platform, finding the right baker for your event becomes a breeze.</li>
                </ul>

                <h4 className="text-highlighter mt-3 text-center">For Bakers:</h4>
                <ul className="mt-3">
                  <li><strong>Expand Your Reach:</strong> Join a thriving community, increasing your visibility to potential customers in your locality.</li>
                  <li><strong>Get Featured:</strong> Boost your business's presence! We'll feature you on our social media platforms, tagging your business on Instagram, Facebook, and more, amplifying your reach.</li>
                  <li><strong>Certification Support:</strong> Just starting out? Our TrustedBakers Academy offers Level 2 Food Hygiene & Safety training, helping you get certified and start your baking journey on the right foot.</li>
                  <li><strong>Networking:</strong> Engage with a network of experienced bakers, share ideas, and grow together.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
