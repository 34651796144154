import React from "react";
import {Link} from "react-router-dom";
import footerbg from "../img/footer/foot-bg.png";

const Footer = () => {
  return (
    <>
      <footer
        id="apork-footer-area"
        className="apork-footer-area"
        style={{ background: `url('${footerbg}')` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="footer-copyright text-center pt-40 pb-40">

                <p>
                  &copy; 2023 TrustedBakers.
                </p>
                <ul className="footer-social">
                  <li>
                    <a href="https://www.facebook.com/trustedbakers">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/trusted_bakers/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">
                      Terms and Condition
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
