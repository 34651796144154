import React, {Component} from "react";
import shape2 from "../img/features/bg-shape-2.png";
import websafeLogo from "../img/trusted_baker_512.png";
import okIcon from "../img/websafe365/icons8-ok-64.png";
import {withRouter} from "react-router";

class CheckoutRedirect extends Component {
    constructor(props) {
        super(props);
        let redirectData = '';
        try {
            redirectData = JSON.parse(Buffer.from(props.match.params.redirectData, "base64").toString());
            if(redirectData.t > Date.now()){
                setTimeout(function () {
                    window.location = redirectData.link;
                }, 6000);
            }else {
                window.location = redirectData.link;
            }

        } catch (e) {
            window.location.href = "https://websafe365.com?urlExpired=true";
        }
    }

    render() {
        return (
            <div
                id="checkout-body"
                className="checkout-body">
                <div className="features-bg-shape">
                    <img src={shape2} alt="featuresimg" className="style-shape fbs-2"/>
                </div>
                <div className="container padding-top-x1">
                    <div className="row text-center">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="checkout single-features">
                                <h3 className="text-left text-highlighter">Trusted Bakers Premium features:</h3>
                                <table className="text-left">
                                    <tbody>
                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>Unlimited websites management.</td>
                                    </tr>

                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>Set any time limit.</td>
                                    </tr>

                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>Unlimited websites management.</td>
                                    </tr>
                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>Manage rejected or accepted site list.</td>
                                    </tr>
                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>1 year unlimited access.</td>
                                    </tr>
                                    </tbody>

                                </table>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 wow fadeInRight bg-black">
                                <div className="checkout single-features">
                                    <img src={websafeLogo} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                    <div className="padding-bottom-x1">
                                        <a href="/#"> Redirecting to checkout...</a>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


}

export default withRouter(CheckoutRedirect);
