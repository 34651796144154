import React, {Component} from "react";
import {Link} from "react-router-dom";

class TermsOfSales extends Component {

    render() {
        return (
            <div className="terms">
                <div className="container">
                    <ol className="breadcrumb">
                        <li><a href="/">Home > </a></li>
                        <li className="active">Terms and Conditions</li>
                    </ol>
                    <section className="page-title center">
                        <h1 className="uppercase">Terms and Conditions</h1>
                    </section>

                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>1. TrustedBakers Introduction</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>These Terms and Conditions applies to the users of trustedbakers.com website,
                                        TrustedBakers Mobile app (Android/iOS).
                                        By accessing this website and our mobile apps we assume you
                                        accept these terms and conditions
                                        and our <Link to="/privacy-policy">privacy policy</Link> in full.</b>

                                    </p>
                                    <p>Do not continue to use TrustedBakers products if you do not accept all of the
                                        terms and conditions, privacy and cookie policy.</p>
                                    <p>The following terminology applies to these Terms and Conditions, Privacy
                                        Statement and Disclaimer Notice and any or all Agreements:</p>
                                    <p><b>'TrustedBakers' is a trading name for Innovotech Solutions Ltd, a company
                                        registered in England & Wales (company number: 10641546) . Our registered
                                        office address is at 128 City Road, London, England, EC1V 2NX, United Kingdom</b>.</p>
                                    <p>"TrustedBakers", "The Company", “Ourselves”, “We”, “Our” and "Us", refers to
                                        our company, <b>Innovotech Solutions Ltd.</b></p>
                                    <p>"trustedbakers.com website", "TrustedBakers Mobile app (Android/iOS)" collective
                                        referred as "Our Products".</p>
                                    <p>"User", "Client", "Customer", “You” and “Your” refers to you, the
                                        person accessing "Our Products" and accepting these terms and conditions and our
                                        privacy policy.</p>

                                    <p>“Baker”, “TrustedBaker” is refers to an independant bakers signed up to our
                                        platform to offer baking products.</p>

                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>2. Our Service</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b>TrustedBakers is a local baker enquiry platform</b></p>
                                    <p><b>TrustedBakers offers a gallery of customised handcrafted bakery products
                                        crafted by our registered bakers.</b></p>
                                    <p>Bakers on our platform are independent and are free to engage with our customers
                                        directly.</p>
                                    <p>Our customers can enquire about any bakery product listed on our website by our
                                        registered bakers.</p>
                                    <p>Bakers typically reply within few ours to 24 hours to any enquiry.</p>
                                    <p>We will send you an email and a app notification confirming if your baking
                                        enquiry has been accepted or requires more information.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>3. Bakers Registration</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p className="spaced-para">At TrustedBakers.com, we are committed to maintaining high-quality standards and ensuring that our platform serves as a trusted marketplace for consumers and bakers alike. Therefore, we have set specific registration criteria for bakers wishing to list their products:</p>

                                    <ul>
                                        <li>All bakers/chefs must possess a Level 2 or higher Food Hygiene and Safety certification.</li>
                                        <li>Proof of a Local Authority (Environmental Health Officer or EHO) inspection or an FSA (Food Standards Agency) hygiene rating is mandatory before listing products.</li>
                                        <li>Monthly subscription fee is £10, can be cancelled anytime.</li>
                                    </ul>
                                    <p className="spaced-para">Beginner bakers or chefs, who do not have the Level 2 Food Hygiene certification, can register via our mobile app. We will guide you to register as a food business and acquiring a Level 2 or higher Food Hygiene certification before you start listing.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>4. Payment</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> Our website uses secure SSL/ HTTPS communication for all interactions
                                        between your browser and our servers.</b></p>
                                    <p> All payments are handled securely by our payment service provider, <b>Stripe
                                        Inc</b> (stripe.com).
                                        Stripe is major payment provider in the United States and approved by the
                                        Financial Conduct Authority (FCA) in the UK.</p>
                                    <p> All payments are final and includes any taxes at the prevailing rate.</p>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>7. Your Personal Data and Privacy</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <ul className="bulleted">
                                        <li> We do not share your contact information with 3rd parties. However, for the
                                            purpose of payment processing, we share your personal information with our
                                            payment service provider(<b>Stripe.com</b>).
                                        </li>
                                    </ul>
                                    <p><b>Please read our
                                        <Link to="/privacy-policy"> Privacy Policy </Link> in full for further details.</b>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>8. Limitations & Liabilities</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <ul className="bulleted">
                                        <li>TrustedBakers is only acting as introducer to your local bakers near you.
                                        </li>
                                        <li>We do not take any responsibility or any liability for any food product made
                                            by our independent bakers.
                                        </li>
                                        <li>All bakers on our platform are independent and they will provide services
                                            directly to you.
                                            As We/TrustedBakers.com are not party to any conversation/vetting, we
                                            discharge all liabilities to you.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>9. Contacting us</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> You can contact us via info@trustedbakers.com</b></p>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </div>
        );
    };


}

export default TermsOfSales;
